import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LocationOn from "@material-ui/icons/LocationOn";
import Payment from "@material-ui/icons/Payment";
import Receipt from "@material-ui/icons/Receipt";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import Maps from "views/Maps/Maps.js";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Property Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/map",
    name: "View Map",
    icon: LocationOn,
    component: Maps,
    layout: "/admin"
  },
  {
    path: "/payment",
    name: "Payment",
    icon: Payment,
    component: UserProfile,
    layout: "/admin"
  },
  {
    path: "/history",
    name: "Transaction History",
    icon: Receipt,
    component: UserProfile,
    layout: "/admin"
  },
  {
    path: "/profile",
    name: "Profile",
    icon: Person,
    component: UserProfile,
    layout: "/admin"
  }
];

export default dashboardRoutes;
