import React from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/icons
import Home from "@material-ui/icons/Home";
import Description from "@material-ui/icons/Description";
import Explore from "@material-ui/icons/Explore";
import Warning from "@material-ui/icons/Warning";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/SupervisorAccount";
import systemupdate from "@material-ui/icons/ArrowDownward"
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Button from "@material-ui/core/Button"
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import MaterialTable from "material-table";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} lg={6}>
          <GridContainer>
            <GridItem xs={12} xl={12}>
              <Card>
                <CardHeader color="info" stats icon>
                  <CardIcon color="info">
                    <Home />
                  </CardIcon>
                  <p className={classes.cardCategory}>Property Information</p>
                  <h3 className={classes.cardTitle}>302 East Street</h3>
                  <h3 className={classes.cardTitle}>Jonesville, Michigan 49250-1259</h3>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={6} xl={6}>
                      <h3>
                        Assessed Value: <br /><b>$193,312</b>
                      </h3>
                    </GridItem>
                    <GridItem xs={6} xl={6}>
                      <h3>
                        Final Taxable Value: <br /><b>$187,993</b>
                      </h3>
                    </GridItem>
                  </GridContainer>
                  <Table
                    tableData={[
                      ["Floor Area", "2,736 sq ft"],
                      ["Garage Area", "400 sq ft"],
                      ["Foundation Size", "1,609 sq ft"],
                      ["Year Built", "1981"],
                      ["Occupancy", "Single Family"],
                      ["Heating", "Forced Warm Air"],
                      ["Cooling", "Air Conditioning"],
                      ["Style", "2 Story"],
                      ["Bedrooms", "4"],
                      ["Bathrooms", "2"],
                      ["Lavatories", "2"],
                      ["Exterior", "Brick"],
                    ]}
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} xl={12}>
              <Card>
                <CardHeader color="info" stats icon>
                  <CardIcon color="info">
                    <Explore />
                  </CardIcon>
                  <p className={classes.cardCategory}>Land Information</p>
                  <h3 className={classes.cardTitle}>Acreage: <b>0.26 acres</b></h3>
                </CardHeader>
                <CardBody>
                  <Table
                    tableData={[
                      ["Latitude", "41.981934"],
                      ["Longitude", "-84.658508"],
                      ["Census Tract", "0505"],
                      ["Block Group", "2"],
                      ["Property Category", "Residential"],
                      ["Land Use", "401 - Residential"],
                      ["Land Dimensions", "99x115"],
                      ["Legal Description", "ELY 1/2 LOT 9 EXC SLY 3 RD ELY 1/2 LOT 10 LYTLES ADD, BLOCK 3 SEC 4 T6S R3W CITY OF JONESVILLE"]
                    ]}
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} xl={12}>
              <Card>
                <CardHeader color="info" stats icon>
                  <CardIcon color="info">
                    <Accessibility />
                  </CardIcon>
                  <p className={classes.cardCategory}>Owner Information</p>
                  <h3 className={classes.cardTitle}>David M Holcomb</h3>
                  <h3 className={classes.cardTitle}>Kimberly J Holcomb</h3>
                </CardHeader>
                <CardBody>
                  <Table
                    tableHead={["Grantor", "Grantee", "Record Date", "Deed Date", "Price", "Deed Type"]}
                    tableData={[
                      ["Jenny Wise", "David M Holcomb/Kimberly J Holcomb", "05/23/2018", "06/29/2018", "$342,152", "WD"],
                      ["United Wholesale Mortgage", "Jenny Wise", "04/13/2016", "05/21/2016", "$358,754", "WD"],
                      ["Jack Johnson", "United Wholesale Mortgage", "08/12/2003", "08/21/2003", "$214,525", "WD"],
                    ]}
                  />
                </CardBody>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <Update />
                    Just Updated
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} lg={6}>
          <GridContainer>
            <GridItem xs={12} xl={12}>
              <Card chart>
                <CardHeader color="warning">
                <ChartistGraph
                    className="ct-chart"
                    data={dailySalesChart.data}
                    type="Line"
                    options={dailySalesChart.options}
                    listener={dailySalesChart.animation}
                  />
                </CardHeader>
                <CardBody>
                  <CardIcon color="warning">
                    <Icon>payment</Icon>
                  </CardIcon>
                  
                  <GridContainer alignItems="center" justify="center" alignContent="center">
                    <GridItem xs={6} xl={6}>
                      <p className={classes.cardCategory}>Pay Taxes Online</p>
                      <h3 className={classes.cardTitle}>
                      $4,531.<small>33</small>
                      </h3>
                    </GridItem>
                    <GridItem xs={6} xl={6}>
                      <p className={classes.cardTitle}>
                      due 12/30/2019
                      </p>
                      <Button variant="contained" color="secondary" className={classes.button}>
                      Pay Now
                      </Button>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <MaterialTable
                    title="Taxes"
                    columns={[ {title:"Season", field:"season"}, {title:"Year", field:"year"} , {title:"Amount", field:"amt"}, {title:"Payment Date", field:"pmtDate"} ]}
                    data={[
                      {season:"Winter", year:"2019", amt:"$1,119.21", pmtDate:"Due 12/30/2019"},
                      {season:"Summer", year:"2019", amt:"$3,412.12", pmtDate:"Due 12/30/2019"},
                      {season:"Winter", year:"2018", amt:"$1,039.11", pmtDate:"11/23/2018"},
                      {season:"Summer", year:"2018", amt:"$3,313.13", pmtDate:"11/23/2018"},
                      {season:"Winter", year:"2017", amt:"$987.41", pmtDate:"12/05/2017"},
                      {season:"Summer", year:"2017", amt:"$3,115.82", pmtDate:"08/17/2017"},
                      {season:"Winter", year:"2016", amt:"$953.68", pmtDate:"12/19/2016"},
                      {season:"Summer", year:"2016", amt:"$2,993.64", pmtDate:"07/24/2016"},
                      {season:"Winter", year:"2015", amt:"$927.41", pmtDate:"01/12/2016"},
                      {season:"Summer", year:"2015", amt:"$2,767.14", pmtDate:"08/10/2015"}
                    ]}
                    actions={[
                      rowData => ({
                        icon: 'payment',
                        tooltip: 'Pay Now',
                        onClick: (event, rowData) => alert("You saved " + rowData.amt),
                        hidden: !rowData.pmtDate.startsWith("Due"),
                        color: "secondary"
                      })
                    ]}
                    options={{
                      actionsColumnIndex: -1
                    }}
                  />
                  <br />            
                  <h4 className={classes.cardTitle}>Taxes Year Over Year</h4>
                  <p className={classes.cardCategory}>
                    <span className={classes.warningText}>
                      <ArrowUpward className={classes.upArrowCardCategory} /> 4.1%
                    </span>{" "}
                    increase in taxes from 2018 to 2019.
                  </p>
                </CardBody>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <Danger>
                      <Warning />
                    </Danger>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      Pay now to avoid late penalties
                    </a>
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} xl={12}>
              <Card>
                <CardHeader color="info" stats icon>
                  <CardIcon color="info">
                    <Description />
                  </CardIcon>
                  <br />
                  <h3 className={classes.cardTitle}>Documents</h3>
                </CardHeader>
                <CardBody>
                  <MaterialTable
                      title=""
                      columns={[ {title:"Document Name", field:"name"}, {title:"Upload Date", field:"date"}]}
                      data={[
                        {name:"MBOR 2019 Assessment", date:"11/30/2019"},
                        {name:"MBOR 2018 Assessment", date:"11/25/2018"},
                        {name:"Building Sketch First Floor", date:"6/3/2013"},
                        {name:"Building Sketch Second Floor", date:"6/3/2013"},
                        {name:"MBOR 2012 Assessment", date:"11/29/2012"},
                        {name:"MBOR 2011 Assessment", date:"11/23/2011"}
                      ]}
                      actions={[
                        rowData => ({
                          icon: systemupdate,
                          tooltip: 'Download',
                          onClick: (event, rowData) => alert("You downloaded " + rowData.name),
                          color: "secondary"
                        })
                      ]}
                      options={{
                        actionsColumnIndex: -1
                      }}
                    />
                </CardBody>
              </Card>
            </GridItem>
            
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}
